import React from "react";
import Modal from "react-modal";
import { Container, Row, Col, Dropdown, ButtonGroup, ToggleButton } from "react-bootstrap";
import Button from 'react-bootstrap/Button'
import PersonaImage from "./PersonaImage";
import BasicPropertyTable from "./BasicPropertyTable";
import LifeStyleTable from "./LifeStyleTable";
import BrandTable from "./BrandTable";
import cubes from "./images/icons/cubes.png";
import analyze from "./images/icons/analyze.png";
import "./App.css";
import ReactGridTreeDataBinding from "./ReactGridTreeDataBinding";
import filter from "./images/icons/filter_white.png";
import Toast from 'react-bootstrap/Toast';
// IDB対応
import { setDS, getDS } from "./DataStore";
// ADD API共通処理
import Api from "./Api";
import {formatSurveyYear, formatSurvey, formatSs} from "./common";
// import { appendArrayMultiPointValues } from "plotly.js/dist/plotly-cartesian";
Modal.setAppElement("#root");
 
/**
 * トップページ描画クラス
 */
class Top extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      viewModalIsOpen: false,
      filterModalIsOpen: false,
      requestBasic: "",
      requestLifestyle: "",
      requestBrand: "",
      requestPersona: "",
      responseBasic: "",
      responseLifestyle: "",
      responseBrand: "",
      responsePersona: "",
      responseFreeform: "",
      filterJson: "",
      filterData: "",
      personaDisplayList: [0, 1, 2, 3],
      personaPrevDisplayList: [0, 1, 2, 3],
      brandDisplayList: 0,
      brandPrevDisplayList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      lifestyleDisplayList: 0 ,
      lifestylePrevDisplayList: [4, 5, 6, 20, 21, 0, 1, 2, 3, 7, 8, 9, 10, 11, 12],
      compressedKey: "",
      compressState: "end",
      radioValue: 0,
      isDisplay: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.setRequestBasic = this.setRequestBasic.bind(this);
    this.setRequestLifestyle = this.setRequestLifestyle.bind(this);
    this.setRequestPersona = this.setRequestPersona.bind(this);
    this.setRequestBrand = this.setRequestBrand.bind(this);
    this.mypageAjax = this.mypageAjax.bind(this);
    this.requestAjax = this.requestAjax.bind(this);
    this.setDisplayPersona = this.setDisplayPersona.bind(this);
    this.setPrevDisplayPersona = this.setPrevDisplayPersona.bind(this);
    this.setDisplayBrand = this.setDisplayBrand.bind(this);
    this.setPrevDisplayBrand = this.setPrevDisplayBrand.bind(this);
    this.setDisplayLifestyle = this.setDisplayLifestyle.bind(this);
    this.setPrevDisplayLifestyle = this.setPrevDisplayLifestyle.bind(this);
    this.setCompressState = this.setCompressState.bind(this);
    this.setRadioValue = this.setRadioValue.bind(this);
  }
 
  /**
   * 基本属性で絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestBasic(basic_list) {
    let lifestyle_list = this.state.requestLifestyle;
    let purchasing_behavior_list = this.state.requestBrand;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.setState({ requestBasic: basic_list });
    this.requestAjax.bind(this, filterData)();
  }
 
  /**
   * ライフスタイルで絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestLifestyle(lifestyle_list) {
    this.setState({ requestLifestyle: lifestyle_list });
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.requestAjax.bind(this, filterData)();
  }
 
  /**
   * 購買行動で絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {Array} basic_list - 基本属性の絞り込み条件
   * @param {Array} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {Array} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {Array} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestBrand(purchasing_behavior_list) {
    this.setState({ requestBrand: purchasing_behavior_list });
    let lifestyle_list = this.state.requestLifestyle;
    let basic_list = this.state.requestBasic;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.requestAjax.bind(this, filterData)();
  }
 
  /**
   * ペルソナスケルトンで絞り込みが行われた時、リクエストをその絞り込み条件に更新する
   * @param {object} basic_list - 基本属性の絞り込み条件
   * @param {object} lifestyle_list - ライフスタイルの絞り込み条件
   * @param {object} purchasing_behavior_list - 購買行動の絞り込み条件
   * @param {object} persona_list - ペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  setRequestPersona(persona_list) {
    let lifestyle_list = this.state.requestLifestyle;
    let purchasing_behavior_list = this.state.requestBrand;
    let basic_list = this.state.requestBasic;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.setState({ requestPersona: persona_list });
    this.requestAjax.bind(this, filterData)();
  }
 
  /**
   * ペルソナスケルトンテーブルで表示切替が行われた時、画面表示用リストを更新する
   * @param {Array} displayList - ペルソナスケルトン4軸画面表示用idリスト
   */
  setDisplayPersona(displayList) {
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let lifestyle_list = this.state.requestLifestyle;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = displayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.setState({ 
      personaDisplayList: displayList,
      filterJson: filterData
     });
     this.props.setFilterState(JSON.parse(JSON.stringify(filterData)));
     setDS([JSON.stringify(JSON.parse(JSON.stringify(filterData)))])
       .then(compress_response => {
         this.setState({
           compressedKey: compress_response[JSON.parse(JSON.stringify(JSON.stringify(filterData)))]
         });
      })
  }
 
  /**
   * ペルソナスケルトンテーブルで表示切替が行われた時、直前の画面表示状態をリストに保存する
   * @param {Array} displayPrevList - 表示切替直前まで画面表示されていた4軸のidリスト
   */
  setPrevDisplayPersona(displayPrevList) {
    this.setState({ personaPrevDisplayList: displayPrevList });
  }
 
  /**
   * 購買行動テーブルで表示切替が行われた時、画面表示用リストを更新する
   * @param {Array} displayList - 購買行動5ブロック画面表示用idリスト
   */
  setDisplayBrand(displayList) {
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let lifestyle_list = this.state.requestLifestyle;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = this.state.lifestyleDisplayList;
    let switch_purchasing_behavior_list = displayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.setState({ 
      brandDisplayList: displayList,
      filterJson: filterData
     });
     this.props.setFilterState(JSON.parse(JSON.stringify(filterData)));
     setDS([JSON.stringify(JSON.parse(JSON.stringify(filterData)))])
       .then(compress_response => {
         this.setState({
           compressedKey: compress_response[JSON.parse(JSON.stringify(JSON.stringify(filterData)))]
         });
       })
  }
 
  /**
   * 購買行動テーブルで表示切替が行われた時、直前の画面表示状態をリストに保存する
   * @param {Array} displayPrevList - 表示切替直前まで画面表示されていた5ブロックのidリスト
   */
  setPrevDisplayBrand(displayPrevList) {
    this.setState({ brandPrevDisplayList: displayPrevList });
  }
 
  /**
   * ライフスタイルテーブルで表示切替が行われた時、画面表示用リストを更新する
   * @param {Array} displayList - ライフスタイルブロック画面表示用idリスト
   */
  setDisplayLifestyle(displayList) {
    let basic_list = this.state.requestBasic;
    let purchasing_behavior_list = this.state.requestBrand;
    let lifestyle_list = this.state.requestLifestyle;
    let persona_list = this.state.requestPersona;
    let switch_lifestyle_list = displayList;
    let switch_purchasing_behavior_list = this.state.brandDisplayList;
    let switch_persona_list = this.state.personaDisplayList;
    let filterData = {
      basic_list,
      lifestyle_list,
      persona_list,
      purchasing_behavior_list,
      switch_lifestyle_list,
      switch_purchasing_behavior_list,
      switch_persona_list,
      "switch_persona_detail": false,
      "switch_survey": formatSurvey()
    };
    this.setState({ 
      lifestyleDisplayList: displayList,
      filterJson: filterData
     });
    this.props.setFilterState(JSON.parse(JSON.stringify(filterData)));
    setDS([JSON.stringify(JSON.parse(JSON.stringify(filterData)))])
      .then(compress_response => {
        this.setState({
          compressedKey: compress_response[JSON.parse(JSON.stringify(JSON.stringify(filterData)))]
        });
      })
  }
 
  /**
   * ライフスタイルテーブルで表示切替が行われた時、直前の画面表示状態をリストに保存する
   * @param {Array} displayPrevList - 表示切替直前まで画面表示されていたライフスタイルブロックのidリスト
   */
  setPrevDisplayLifestyle(displayPrevList) {
    this.setState({ lifestylePrevDisplayList: displayPrevList });
  }
 
  /**
   * URL圧縮処理が処理中なのか終了しているのかの状態を保存する
   * @param {string} state - URL圧縮処理の状態
   */
  setCompressState(state){
    this.setState({compressState: state});
  }
 
  /**
   * (React組み込み関数)初期画面表示のためのデータを整理する
   * https://ja.reactjs.org/docs/react-component.html#unsafe_componentwillmount
   * @param {string} get - URLから切り取った絞り込み条件
   * @param {string} jwt - 認証情報
   */
  UNSAFE_componentWillMount() {
    if (window.location.hash.split("#/?s=")[1] !== undefined) {
      let jwt = localStorage.getItem("it");
      let s;
      // TODO fixme どちらも存在しない場合エラーにする
      if (!sessionStorage.getItem("ss") && !localStorage.getItem("ss")) {
        localStorage.removeItem("tr");
        localStorage.removeItem("ci");
        localStorage.removeItem("it");
        localStorage.removeItem("sy");
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.removeItem("se");
        window.location.href = "./#/login";
      }
      // セッションストレージに無い（初期遷移）の場合はローカルストレージの値を引用する
      else if(!sessionStorage.getItem("ss") || sessionStorage.getItem("ss") === "undefined"){
        s = JSON.parse(localStorage.getItem("ss"));
        sessionStorage.setItem("ss", localStorage.getItem("ss"));
      }
      let self = this;
      let sex = 0;
      // 2021以前ならスキップ
      if(Number(formatSurvey().substr(0, 4)) >= 2022){
        // 性別情報を取り出す
        const sexId = window.location.hash.split("s=")[1];
        sex = Number(sexId.slice(0, 1));
        sessionStorage.setItem("se", sex);
        // 1万人女性データのときだけ性別トグルボタンを表示する
        if(formatSurvey().split("_")[1] === "all"){
        // if(formatSurvey().split("_")[1] === process.env.DATA_PATTERN){
          self.setState({
            isDisplay: true,
            radioValue: Number(sex)
          });
        }
      }
      document.title = `${formatSurveyYear()}${Number(sex)? "男性" : "女性"}|TOP`;

      this.mypageAjax();
    }
    else {
      let jwt = localStorage.getItem("it");
      let s;
      // TODO fixme どちらも存在しない場合エラーにする
      if (!sessionStorage.getItem("ss") && !localStorage.getItem("ss")) {
        localStorage.removeItem("tr");
        localStorage.removeItem("ci");
        localStorage.removeItem("it");
        localStorage.removeItem("sy");
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.removeItem("se");
        window.location.href = "./#/login";
      }
      // セッションストレージに無い（初期遷移）の場合はローカルストレージの値を引用する
      else if(!sessionStorage.getItem("ss") || sessionStorage.getItem("ss") === "undefined"){
        s = JSON.parse(localStorage.getItem("ss"));
        sessionStorage.setItem("ss", localStorage.getItem("ss"));
      }

      // ADD 初回アクセス時はログイン画面にリダイレクト
      if (typeof jwt === "undefined") {
        window.location.href = "./#/login";
      }

      let self = this;
      let get = window.location.hash.split("filter=")[1];

      let sex = 0;

      console.log("sex",sex);
      if (get !== undefined) {
        return false;
      }
      else {
        let filterData = {"survey": formatSurvey(), "sex": sex};
        new Api().post('/api/v1/filter', filterData,
          response => {
            // 帳票出力用のidを切り替える
            let lifestyleIdList = [];
            let brandIdList = [];
            if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2019"){
              lifestyleIdList = [4, 5, 6, 20, 21, 0, 1, 2, 3, 7, 8, 9, 10, 11, 12];
              brandIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
              self.setState({isDisplay: false})
            }
            else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2020"){
              lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 16, 23, 8, 9, 10, 11, 12];
              brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
              self.setState({isDisplay: false})
            }
            else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2021"){
              lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 15, 23, 24, 25, 26, 27, 28];
              brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
              self.setState({isDisplay: false})
            }
            else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2022"){
              if(Number(response.data.purchasing_behavior_list[0].sex)){
                // 男性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
              }
              else{
                // 女性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 46];
              }
            }
            else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2023"){
              if(Number(response.data.purchasing_behavior_list[0].sex)){
                // 男性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
              }
              else{
                // 女性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
              }
            }
            else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2024"){
              if(Number(response.data.purchasing_behavior_list[0].sex)){
                // 男性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
              }
              else{
                // 女性
                lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
                brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
              }
            }
            // 2021以前ならスキップ
            if(formatSurveyYear() === "2022" || formatSurveyYear() === "2023" || formatSurveyYear() === "2024"){
              // 性別トグルボタンを表示させるかどうか判定
              // 1万人女性データのときだけ性別トグルボタンを表示する
              if(formatSurvey().split("_")[1] === "all"){
              // if(formatSurvey().split("_")[1] === process.env.DATA_PATTERN){
                self.setState({isDisplay: true});
              }
              else{
                self.setState({isDisplay: false});
              }
              // トグルONを男女どちらにするか調べる
              if(!sessionStorage.getItem("se")){
                sex = Number(localStorage.getItem("se"));
              }
              else{
                sex = Number(sessionStorage.getItem("se"));
                this.setState({radioValue: Number(sex)});
              }
            }

            let lifestyle_list = response.data.filter_state.lifestyle_list;
            let basic_list = response.data.filter_state.basic_list;
            let persona_list = response.data.filter_state.persona_list;
            let purchasing_behavior_list = response.data.filter_state.purchasing_behavior_list;
            let switch_lifestyle_list = lifestyleIdList;
            let switch_purchasing_behavior_list = brandIdList;
            let switch_persona_list = [0, 1, 2, 3];
            let filterData = {
              basic_list,
              lifestyle_list,
              persona_list,
              purchasing_behavior_list,
              switch_lifestyle_list,
              switch_purchasing_behavior_list,
              switch_persona_list,
              "switch_persona_detail": false,
              "switch_survey": formatSurvey()
            };
            self.setState({
              requestBasic: response.data.filter_state.basic_list,
              requestLifestyle: response.data.filter_state.lifestyle_list,
              requestBrand: response.data.filter_state.purchasing_behavior_list,
              requestPersona: response.data.filter_state.persona_list,
              responseBasic: response.data.basic_list,
              responseBrand: response.data.purchasing_behavior_list,
              responseLifestyle: response.data.lifestyle_list,
              responseFreeform: response.data.freeform_list,
              responsePersona: response.data.persona_list,
              filterJson: filterData,
              // survey: response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4),
              lifestyleDisplayList: lifestyleIdList,
              brandDisplayList: brandIdList,
            });
            // self.props.setSurvey(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4));
            self.props.setFilterState(filterData);
            setDS([JSON.stringify(filterData)])
              .then(compress_response => {
                self.setState({
                  compressedKey: compress_response[JSON.stringify(filterData)]
                });
              })
          });
        document.title = `${formatSurveyYear()}${Number(sex)? "男性" : "女性"}|TOP`;
      }
    }
  }
 
  /**
   * (React組み込み関数)絞り込み結果が更新された時、画面表示用にデータを整理する
   * https://ja.reactjs.org/docs/react-component.html#componentdidupdate
   * @param {object} prevProps - 条件一覧モーダル上で行われた絞り込み条件
   * @param {object} basic_list - 条件一覧モーダル上で行われた基本属性の絞り込み条件
   * @param {object} lifestyle_list - 条件一覧モーダル上で行われたライフスタイルの絞り込み条件
   * @param {object} purchasing_behavior_list - 条件一覧モーダル上で行われた購買行動の絞り込み条件
   * @param {object} persona_list - 条件一覧モーダル上で行われたペルソナスケルトンの絞り込み条件
   * @param {object} filterData - filterAPIのリクエスト
   */
  componentDidUpdate(prevProps) {
    let self = this;
    // ブラウザタブタイトルを変更する
    let survey = formatSurveyYear();
    let sex = Number(JSON.parse(sessionStorage.getItem("ss")).sex)? "男性" : "女性";
    document.title = `${survey}${sex}|TOP`;

    if (prevProps.listOfReFilterInTop !== this.props.listOfReFilterInTop) {
      let basic_list = this.props.listOfReFilterInTop.basic_list;
      let lifestyle_list = this.props.listOfReFilterInTop.lifestyle_list;
      let persona_list = this.props.listOfReFilterInTop.persona_list;
      let purchasing_behavior_list = this.props.listOfReFilterInTop.purchasing_behavior_list;
      let switch_lifestyle_list = this.props.listOfReFilterInTop.switch_lifestyle_list;
      let switch_purchasing_behavior_list = this.props.listOfReFilterInTop.switch_purchasing_behavior_list;
      let switch_persona_list = this.props.listOfReFilterInTop.switch_persona_list;

      this.setState({
        requestBasic: basic_list,
        requestLifestyle: lifestyle_list,
        requestBrand: purchasing_behavior_list,
        requestPersona: persona_list
      });
      let filterData = {
        basic_list,
        lifestyle_list,
        persona_list,
        purchasing_behavior_list,
        switch_lifestyle_list,
        switch_purchasing_behavior_list,
        switch_persona_list,
        "switch_persona_detail": false,
        "switch_survey": formatSurvey()
      };
      this.requestAjax.bind(this, filterData)();
    }
  }

  /**
   * マイページ内にあるページ表示ボタン押下時に、トップページでの絞り込み結果を再現させる
   * @param {string} hash - URLの#以降を切り取りデコードした文字列
   * @param {string} get - hashから絞り込み条件のみを切り取った文字列
   * @param {string} jwt - 認証情報
   * @param {JSON} filterData - filterAPIのリクエスト
   * @param {string} filterJson - 展開した絞り込み条件の文字列
   */
  mypageAjax() {
    let self = this;
    let hash = decodeURIComponent(window.location.hash);
    let get = hash.split("filter=")[1];
    getDS([get])
      .then(response_key => {
        // 調査データ判定
        let survey;
        let query = JSON.parse(response_key[get]);
        let assumed_surveys = JSON.parse(localStorage.getItem("sy")).filter((sv)=> {return sv.name.indexOf(query.switch_survey) !== -1});
        console.log(assumed_surveys);
        survey = assumed_surveys.slice(-1)[0].name;

        let sex = 0;
        // 2021以前ならスキップ
        if(formatSurveyYear() === "2022" || formatSurveyYear() === "2023" || formatSurveyYear() === "2024"){
          // 性別情報を取り出す
          const sexId = window.location.hash.split("s=")[1];
          sex = Number(sexId.slice(0, 1));
          sessionStorage.setItem("se", sex);
        }

        document.title = `${formatSurveyYear()}${Number(sex)? "男性" : "女性"}|TOP`;
        localStorage.removeItem("ss");
        localStorage.removeItem("sv");
        localStorage.setItem("ss", formatSs(survey, sex));
        localStorage.setItem("sv", survey);
        sessionStorage.removeItem("ss");
        sessionStorage.setItem("ss", formatSs(survey, sex));
        new Api().post("/api/v1/filter",{"filters": JSON.parse(response_key[get]), "survey": survey, "sex": sex}, 
        response => {
            // TODO 暫定対応 2019年版の保存データにswich系リストが含まれない場合には無条件で初期化する
            let switch_lifestyle_list = [];
            if (response.data.filter_state.switch_lifestyle_list?.length){
              switch_lifestyle_list = response.data.filter_state.switch_lifestyle_list;
            }
            let switch_purchasing_behavior_list = [];
            if (response.data.filter_state.switch_purchasing_behavior_list?.length){
              switch_purchasing_behavior_list = response.data.filter_state.switch_purchasing_behavior_list;
            }
            let switch_persona_list = [0,1,2,3];
            if (response.data.filter_state.switch_persona_list?.length){
              switch_persona_list = response.data.filter_state.switch_persona_list;
            }
            // 性別トグルボタンを表示させるかどうか判定
            // 1万人女性データのときだけ性別トグルボタンを表示する
            if(formatSurveyYear() === "2022" && formatSurvey().split("_")[1] === "all"){
            // if(survey === "2022" && formatSurvey().split("_")[1] === process.env.DATA_PATTERN){
              self.setState({isDisplay: true})
            }
            else if(formatSurveyYear() === "2023" && formatSurvey().split("_")[1] === "all"){
              self.setState({isDisplay: true})
            }
            else if(formatSurveyYear() === "2024" && formatSurvey().split("_")[1] === "all"){
              self.setState({isDisplay: true})
            }
            else{
              self.setState({isDisplay: false})
            }
            self.setState({
              requestBasic: JSON.parse(JSON.stringify(response.data.filter_state.basic_list)),
              requestLifestyle: JSON.parse(JSON.stringify(response.data.filter_state.lifestyle_list)),
              requestBrand: JSON.parse(JSON.stringify(response.data.filter_state.purchasing_behavior_list)),
              requestPersona: JSON.parse(JSON.stringify(response.data.filter_state.persona_list)),
              responseBasic: JSON.parse(JSON.stringify(response.data.basic_list)),
              responseBrand: JSON.parse(JSON.stringify(response.data.purchasing_behavior_list)),
              responseLifestyle: JSON.parse(JSON.stringify(response.data.lifestyle_list)),
              responseFreeform: JSON.parse(JSON.stringify(response.data.freeform_list)),
              responsePersona: JSON.parse(JSON.stringify(response.data.persona_list)),
              filterJson: JSON.parse(JSON.stringify(response.data.filter_state)),
              lifestyleDisplayList: switch_lifestyle_list,
              brandDisplayList: switch_purchasing_behavior_list,
              personaDisplayList: switch_persona_list
            });
            // self.props.setSurvey(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4));
            self.props.setFilterState(JSON.parse(JSON.stringify(response.data.filter_state)));
            setDS([JSON.stringify(JSON.parse(JSON.stringify(response.data.filter_state)))])
            .then(compress_response => {
              self.setState({
                compressedKey: compress_response[JSON.parse(JSON.stringify(JSON.stringify(response.data.filter_state)))]
              });
            })
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          alert("認証に失敗しました。ログイン画面に戻ります。\n操作途中のデータは保存されません。");
          window.location.href = "./#/login";
          console.log("error");
          localStorage.removeItem("tr");
          localStorage.removeItem("ci");
          localStorage.removeItem("it");
          localStorage.removeItem("sy");
          localStorage.removeItem("ss");
          localStorage.removeItem("sv");
          localStorage.removeItem("se");
        }
        else {
          console.log("error");
          localStorage.removeItem("ci");
          localStorage.removeItem("tr");
          localStorage.removeItem("it");
          localStorage.removeItem("sy");
          localStorage.removeItem("ss");
          localStorage.removeItem("sv");
          localStorage.removeItem("se");
        }
      })
  }

  /**
   * 各カテゴリで絞り込みが行われる度に、更新されたリクエストで絞り込みを行う
   * @param {object} data - 絞り込み条件
   * @param {string} jwt - 認証情報
   * @param {object} filterData - filterAPIのリクエスト
   */
  requestAjax(data) {
    let filterData;
    let self = this;
    self.setState({ filterJson: "", filterModalIsOpen: false });
    if (data !== "") {
      let sex = 0;
      // 2021以前ならスキップ
      if(formatSurveyYear() === "2022" || formatSurveyYear() === "2023" || formatSurveyYear() === "2024"){
        sex = Number(sessionStorage.getItem("se"));
      }

      filterData = { 
        "filters": data,
        "survey": formatSurvey(),
        "sex": sex
      };
      document.title = `${formatSurveyYear()}${Number(sex)? "男性" : "女性"}|TOP`;
      new Api().post('/api/v1/filter', filterData,
      response => {
      if(response.data.filter_state.basic_list !== undefined){
        self.setState({
          requestBasic: JSON.parse(JSON.stringify(response.data.filter_state.basic_list)),
          requestLifestyle: JSON.parse(JSON.stringify(response.data.filter_state.lifestyle_list)),
          requestBrand: JSON.parse(JSON.stringify(response.data.filter_state.purchasing_behavior_list)),
          requestPersona: JSON.parse(JSON.stringify(response.data.filter_state.persona_list)),
          responseBasic: JSON.parse(JSON.stringify(response.data.basic_list)),
          responseBrand: JSON.parse(JSON.stringify(response.data.purchasing_behavior_list)),
          responseLifestyle: JSON.parse(JSON.stringify(response.data.lifestyle_list)),
          responseFreeform: JSON.parse(JSON.stringify(response.data.freeform_list)),
          responsePersona: JSON.parse(JSON.stringify(response.data.persona_list)),
          filterJson: JSON.parse(JSON.stringify(response.data.filter_state)),
        });
        // self.props.setSurvey(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4));
        self.props.setFilterState(JSON.parse(JSON.stringify(response.data.filter_state)));
        setDS([JSON.stringify(JSON.parse(JSON.stringify(response.data.filter_state)))])
          .then(compress_response => {
            self.setState({
              compressedKey: compress_response[JSON.parse(JSON.stringify(JSON.stringify(response.data.filter_state)))]
            });
          })
      }
      else{
        // 帳票出力用のidを切り替える
        let lifestyleIdList = [];
        let brandIdList = [];
        if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2019"){
          lifestyleIdList = [4, 5, 6, 20, 21, 0, 1, 2, 3, 7, 8, 9, 10, 11, 12];
          brandIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2020"){
          lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 16, 23, 8, 9, 10, 11, 12];
          brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2021"){
          lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 15, 23, 24, 25, 26, 27, 28];
          brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2022"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 46];
          }
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2023"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
          }
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2024"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
          }
        }
        let lifestyle_list = response.data.filter_state.lifestyle_list;
        let basic_list = response.data.filter_state.basic_list;
        let persona_list = response.data.filter_state.persona_list;
        let purchasing_behavior_list = response.data.filter_state.purchasing_behavior_list;
        let switch_lifestyle_list = lifestyleIdList;
        let switch_purchasing_behavior_list = brandIdList;
        let switch_persona_list = [0, 1, 2, 3];
        let filterData = {
          basic_list,
          lifestyle_list,
          persona_list,
          purchasing_behavior_list,
          switch_lifestyle_list,
          switch_purchasing_behavior_list,
          switch_persona_list,
          "switch_persona_detail": false,
          "switch_survey": formatSurvey()
        };
        self.setState({
          requestBasic: response.data.filter_state.basic_list,
          requestLifestyle: response.data.filter_state.lifestyle_list,
          requestBrand: response.data.filter_state.purchasing_behavior_list,
          requestPersona: response.data.filter_state.persona_list,
          responseBasic: response.data.basic_list,
          responseBrand: response.data.purchasing_behavior_list,
          responseLifestyle: response.data.lifestyle_list,
          responseFreeform: response.data.freeform_list,
          responsePersona: response.data.persona_list,
          filterJson: filterData,
          lifestyleDisplayList: lifestyleIdList,
          brandDisplayList: brandIdList,
        });
        self.props.setFilterState(filterData);
        setDS([JSON.stringify(filterData)])
          .then(compress_response => {
            self.setState({
              compressedKey: compress_response[JSON.stringify(filterData)]
            });
          })         
        }
    });
    }
    else {
      let sex = 0;
      // 2021以前ならスキップ
      if(formatSurveyYear() === "2022" || formatSurveyYear() === "2023" || formatSurveyYear() === "2024"){
        sex = Number(sessionStorage.getItem("se"));
      }
      filterData = {"survey": formatSurvey(), "sex": sex};
      new Api().post('/api/v1/filter', filterData,
      response => {
        // 帳票出力用のidを切り替える
        let lifestyleIdList = [];
        let brandIdList = [];
        if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2019"){
          lifestyleIdList = [4, 5, 6, 20, 21, 0, 1, 2, 3, 7, 8, 9, 10, 11, 12];
          brandIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2020"){
          lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 16, 23, 8, 9, 10, 11, 12];
          brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2021"){
          lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 15, 23, 24, 25, 26, 27, 28];
          brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          // self.setState({isDisplay: false})
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2022"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 46];
          }
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2023"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
          }
        }
        else if(response.data.purchasing_behavior_list[0].survey_name.replace(/[^0-9]/g, '').substr(0, 4) === "2024"){
          if(Number(response.data.purchasing_behavior_list[0].sex)){
            // 男性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 9];
          }
          else{
            // 女性
            lifestyleIdList = [0, 1, 2, 3, 4, 5, 6, 7, 8, 16, 26, 27, 28, 29, 30];
            brandIdList = [0, 2, 3, 6, 7, 1, 4, 5, 8, 45];
          }
        }
        let lifestyle_list = response.data.filter_state.lifestyle_list;
        let basic_list = response.data.filter_state.basic_list;
        let persona_list = response.data.filter_state.persona_list;
        let purchasing_behavior_list = response.data.filter_state.purchasing_behavior_list;
        let switch_lifestyle_list = lifestyleIdList;
        let switch_purchasing_behavior_list = brandIdList;
        let switch_persona_list = [0, 1, 2, 3];
        let filterData = {
          basic_list,
          lifestyle_list,
          persona_list,
          purchasing_behavior_list,
          switch_lifestyle_list,
          switch_purchasing_behavior_list,
          switch_persona_list,
          "switch_persona_detail": false,
          "switch_survey": formatSurvey()
        };
        self.setState({
          requestBasic: response.data.filter_state.basic_list,
          requestLifestyle: response.data.filter_state.lifestyle_list,
          requestBrand: response.data.filter_state.purchasing_behavior_list,
          requestPersona: response.data.filter_state.persona_list,
          responseBasic: response.data.basic_list,
          responseBrand: response.data.purchasing_behavior_list,
          responseLifestyle: response.data.lifestyle_list,
          responseFreeform: response.data.freeform_list,
          responsePersona: response.data.persona_list,
          filterJson: filterData,
          lifestyleDisplayList: lifestyleIdList,
          brandDisplayList: brandIdList,
          personaDisplayList: switch_persona_list,
          personaPrevDisplayList: [0, 1, 2, 3]
        });
        self.props.setFilterState(filterData);
        setDS([JSON.stringify(filterData)])
          .then(compress_response => {
            self.setState({
              compressedKey: compress_response[JSON.stringify(filterData)]
            });
          })         
      });
    }
  }
 
  /**
   * 各種モーダルを開く
   * @param {string} type - 開くモーダルの種類
   */
  openModal(type) {
    if (type === "overview") {
      this.setState({ viewModalIsOpen: true });
    } else if (type === "filter") {
      if (localStorage.getItem("tr") === "true") {
        this.setState({ filterModalIsOpen: false });
      }
      else {
        this.setState({ filterModalIsOpen: true });
      }
    }
  }
 
  /**
   * 各種モーダルを閉じる
   */
  closeModal() {
    this.setState({
      viewModalIsOpen: false,
      filterModalIsOpen: false
    });
  }
 
  /**
   * マップページへ遷移する
   * @param {string} userAgent - 使用ブラウザの種類
   * @param {string} href - URLの#以降
   * @param {string} filterData - 文字列化した絞り込み条件
   * @param {string} url_multi - マップページURL 
   */
  handleClick() {
    // let userAgent = window.navigator.userAgent.toLowerCase();
    // if (userAgent.indexOf('trident') !== -1) {
    //   return undefined;
    // }
    // else {
      if (this.state.compressedKey !== "") {
        let origin = window.location.origin;
        let index = Number(formatSurveyYear()) >= 2023 ? 0:1
        return `${origin}/#/map?page=2&index=${index}&s=${this.state.radioValue}&filter=${encodeURIComponent(this.state.compressedKey)}`;
      }
      else { return undefined; }
    // }
  }

  /**
   * 調査データを切り替える
   */
  changeSurvey(survey) {
    localStorage.removeItem("sv");
    localStorage.removeItem("ss");
    sessionStorage.removeItem("ss");
    localStorage.setItem("ss", formatSs(survey.name, survey.sex));
    localStorage.setItem("sv", survey.name);
    sessionStorage.setItem("ss", formatSs(survey.name, survey.sex));

    // 1万人女性データのときだけ性別トグルボタンを表示する
    console.log("changeSurvey")
    console.log(formatSurvey().split("_")[1])
    if(formatSurveyYear() === "2022" && formatSurvey().split("_")[1] === "all"){
    // if(formatSurveyYear() === "2022" && formatSurvey().split("_")[1] === process.env.DATA_PATTERN){
      this.setState({
        isDisplay: true,
        radioValue: Number(survey.sex)
      });
    }
    else if(formatSurveyYear() === "2023" && formatSurvey().split("_")[1] === "all"){
      this.setState({
        isDisplay: true,
        radioValue: Number(survey.sex)
      });
    }
    else if(formatSurveyYear() === "2024" && formatSurvey().split("_")[1] === "all"){
      this.setState({
        isDisplay: true,
        radioValue: Number(survey.sex)
      });
    }
    else{
      console.log("test")
      this.setState({
        isDisplay: false,
        radioValue: Number(survey.sex)
      });
    }

    localStorage.setItem("se", survey.sex);
    sessionStorage.setItem("se", survey.sex);
    this.requestAjax.bind(this, "")();
   }

  /**
   * 性別を切り替える（別データになる）
   * @param {String} v - 性別の値
   */
  setRadioValue(v) {
    console.log("radio value ev", v )
    console.log("radio value", this.state.radioValue )
    this.setState({
      radioValue: Number(v)
    });
    const survey = formatSurvey();
    sessionStorage.removeItem("se");
    sessionStorage.setItem("se", v);
    localStorage.removeItem("se");
    localStorage.setItem("se", v);
    localStorage.setItem("ss", JSON.stringify({"name": survey, "sex": v}));
    sessionStorage.setItem("ss", JSON.stringify({"name": survey, "sex": v}));

    this.requestAjax.bind(this, "")();
  }

  /**
   * 実際にウィンドウに表示する
   * @param {string} cursor - マウスカーソルの種類
   * @param {string} ie11ClassName - class属性の値を設定
   * @param {string} trialDisadle - トライアルユーザの機能制限
   * @param {Array} surveys - 閲覧可能データリスト
   * @param {number} mendataLength - 男性データ所持数
   * @param {string} userAgent - ブラウザの種類
   * @return {JSX} トップページ表示用HTMLを返す
   */
  render() {
    //Internet Explorerかどうか判定
    let cursor, ie11ClassName;
    let userAgent = window.navigator.userAgent.toLowerCase();
    const radios = [
      { name: '男性', value: 1 },
      { name: '女性', value: 0 },
    ];
    if (userAgent.indexOf('trident') !== -1) {
      cursor = "pointer";
      ie11ClassName = "btn btn-secondary btn-sm d-flex";
      // cursor = "not-allowed";
      // ie11ClassName = "btn btn-secondary btn-sm d-flex disabled";
      if (this.state.filterJson === "" || this.state.requestBasic === "" || this.state.requestLifestyle === "" || this.state.requestBrand === "" || this.state.requestPersona === "" || this.state.compressedKey === "") {
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <span>Loading...</span>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
          </div>
        );
      }
      else if(this.state.compressState === "start"){
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <span>Loading...</span>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
          </div>
        );
      }
    }
    else {
      cursor = "pointer";
      ie11ClassName = "btn btn-secondary btn-sm d-flex";
      if (this.state.filterJson === "" || this.state.requestBasic === "" || this.state.requestLifestyle === "" || this.state.requestBrand === "" || this.state.requestPersona === "" || this.state.compressedKey === "") {
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
          </div>
        );
      }
      else if(this.state.compressState === "start"){
        return (
          <div className="d-flex justify-content-center mt-4 pt-4">
            <div className="spinner-border text-secondary" style={{ width: "5rem", height: "5rem" }} role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <Toast style={{ position: 'absolute', top: 10, margin: "auto", color: "#4169e1", width: "600px", height: "100px", backgroundColor: "#e0ffff" }}>
              <Toast.Body>リクエストを処理しています。この処理には時間が掛かる場合があります。しばらくお待ちください。</Toast.Body>
            </Toast>
          </div>
        );
      }
    }
    let trialDisadle = (localStorage.getItem("tr") === "true");
    let surveys = JSON.parse(localStorage.getItem("sy")).filter(d => d.sex === 0);
    const mendataLength = JSON.parse(localStorage.getItem("sy")).filter(d => d.sex === 1).length;
    return (
      <div key="top_top_of_div">
        <Container fluid key="top_container" style={{ backgroundColor: "#f5f5f5"}} className="print">
          <Row style={{height: 35, display: "flex", justifyContent: "flex-end"}}>

            <div style={{fontSize: "150%"}} className="ml-2">表示データ：</div>
            {this.state.isDisplay && mendataLength?
              <>
                <ButtonGroup>
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={radio.value === 1 ? 'outline-primary' : 'outline-danger'}
                      name="radio"
                      value={radio.value}
                      checked={this.state.radioValue === radio.value}
                      onChange={() => this.setRadioValue(radio.value)}
                      className={() => {
                        // 男性ボタン描画かつ男性チェック
                        if(radio.value === 1 && this.state.radioValue === 1){
                          return "btn-outline-primary-checked"
                        }
                        // 男性ボタン描画かつ女性チェック
                        else if(radio.value === 1 && this.state.radioValue === 0){
                          return "btn-outline-primary-unchecked"
                        }
                        // 女性ボタン描画かつ女性チェック
                        else if(radio.value === 0 && this.state.radioValue === 1){
                          return "btn-outline-danger-checked"
                        }
                        // 女性ボタン描画かつ男性チェック
                        else if(radio.value === 0 && this.state.radioValue === 0){
                          return "btn-outline-danger-unchecked"
                        }
                      }}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
                <div style={{fontSize: "150%"}} className="mx-2">／</div>
              </>:false
            }
            {/* ドロップダウンメニューに変える */}
            <Dropdown>
              <Dropdown.Toggle variant="secondary" id="dropdown-survey" style={{width: "100px"}}>
                {(() => {
                  // 2022女性1万人のときだけ「男女」ラベルをつける
                  if(formatSurvey() === "202211_all"){
                    return formatSurveyYear()+"男女";
                  }
                  else if(formatSurvey() === "202306_all"){
                    return formatSurveyYear()+"男女";
                  }
                  else if(formatSurvey() === "202406_all"){
                    return formatSurveyYear()+"男女";
                  }
                  else{
                    return formatSurveyYear()+"女性";
                  }
                })()}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {surveys.map(d => {
                    const sex_label = (d.name === "202211_all" && d.sex=== 0) || (d.name === "202306_all" && d.sex=== 0) || (d.name === "202406_all" && d.sex=== 0) ? "男女" : "女性";
                    return(
                      <Dropdown.Item 
                        onClick={this.changeSurvey.bind(this, d)}
                        key={d.name}
                      >
                        {/* ドロップダウンメニューには女性データのみ表示のため不要 */}
                        {d.label+sex_label}
                        {/* {d.label} */}
                      </Dropdown.Item>
                    )
                  })
                }
              </Dropdown.Menu>
            </Dropdown>
          </Row>
          <Row>
            <Col md={4}>
              <Row className="justify-content-center">
                <Row className="flex-column w-100">
                  <div className="pt-3 px-3 d-flex flex-column"  >
                    <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">基本属性</b>
                    <BasicPropertyTable
                      key="top_basic"
                      requestBasic={this.state.requestBasic}
                      responseBasic={this.state.responseBasic}
                      setRequestBasic={this.setRequestBasic}
                    />
                  </div>
                </Row>
                <Row className="flex-column w-100">
                  <div className="pt-3 px-3 d-flex flex-column w-100">
                    <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">価値観</b>
                    <LifeStyleTable
                      key="top_life"
                      requestLifestyle={this.state.requestLifestyle}
                      responseLifestyle={this.state.responseLifestyle}
                      setRequestLifestyle={this.setRequestLifestyle}
                      lifestyleDisplayList={this.state.lifestyleDisplayList}
                      lifestylePrevDisplayList={this.state.lifestylePrevDisplayList}
                      setDisplayLifestyle={this.setDisplayLifestyle}
                      setPrevDisplayLifestyle={this.setPrevDisplayLifestyle}
                    />
                  </div>
                </Row>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="justify-content-center">
                <Row className="w-100 flex-column">
                  <div className="pt-3 px-3 d-flex flex-column">
                    <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">ペルソナスケルトン</b>
                    <Button
                      variant="secondary"
                      onClick={
                        this.openModal.bind(this, "overview")}
                      className="ml-auto mb-1"
                      size="sm"
                    >
                      OVERVIEW/表示切替
                      <img src={cubes} alt="" width="15" height="15" className="ml-1 mb-1" />
                    </Button>
                    <PersonaImage
                      key="top_persona"
                      filterJson={this.state.filterJson}
                      modalState={this.state.viewModalIsOpen}
                      filterModalIsOpen={this.state.filterModalIsOpen}
                      close={this.closeModal}
                      requestPersona={this.state.requestPersona}
                      responsePersona={this.state.responsePersona}
                      setRequestPersona={this.setRequestPersona}
                      personaDisplayList={this.state.personaDisplayList}
                      personaPrevDisplayList={this.state.personaPrevDisplayList}
                      setDisplayPersona={this.setDisplayPersona}
                      setPrevDisplayPersona={this.setPrevDisplayPersona}
                      setCompressState={this.setCompressState}
                    />
                  </div>
                </Row>
                <Row className="d-flex justify-content-around m-sm-2 pb-4">
                  <Button
                    variant="secondary"
                    onClick={
                      this.openModal.bind(this, "filter")
                    }
                    className="d-flex mr-2"
                    size="sm"
                    style={{ verticalAlign: "middle" }}
                    disabled={trialDisadle}
                  >
                    ペルソナ絞り込み
                  <img src={filter} alt="" width="15" height="15" className="ml-1 mt-1" />
                  </Button>
                  <a
                    className={ie11ClassName}
                    target="_blank"
                    href={this.handleClick.bind(this)()}
                    role="button"
                    style={{ cursor: cursor, color: "#f5f5f5", verticalAlign: "middle" }}
                    rel="noopener noreferrer"
                  >
                    多変量解析
                  <img src={analyze} alt="" width="15" height="15" className="ml-1 mt-1" />
                  </a>
                </Row>
                <Row className="w-100 flex-column">
                  <div className="d-flex flex-column w-100">
                    <b style={{ fontSize: "150%", borderBottom: "medium solid #000000", position: "relative" }} className="mb-2 pb-2 d-flex w-100 justify-content-center">
                      <div className="w-100" style={{ textAlign: "center" }}>自分らしさ</div>
                    </b>
                    <ReactGridTreeDataBinding
                      key="top_keyword"
                      responseFreeform={this.state.responseFreeform}
                      compressedKey={this.state.compressedKey}
                    />
                  </div>
                </Row>
              </Row>
            </Col>
            <Col md={4}>
              <Row className="align-items-center justify-content-center mt-3">
                <Row className="d-flex flex-column w-100">
                  <div className="px-3 d-flex flex-column">
                    <b style={{ fontSize: "150%", textAlign: "center", borderBottom: "medium solid #000000" }} className="mb-2">ブランド選択</b>
                    <BrandTable
                      key="top_brand"
                      requestBrand={this.state.requestBrand}
                      responseBrand={this.state.responseBrand}
                      setRequestBrand={this.setRequestBrand}
                      brandDisplayList={this.state.brandDisplayList}
                      brandPrevDisplayList={this.state.brandPrevDisplayList}
                      setDisplayBrand={this.setDisplayBrand}
                      setPrevDisplayBrand={this.setPrevDisplayBrand}
                    />
                  </div>
                </Row>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Top;